import { ActionType, getType } from 'deox';
import { SagaIterator } from 'redux-saga';
import { put, takeLatest, call, select } from 'typed-redux-saga';
import './actions';
import { OrderActions } from './actions';
import { AppActions } from 'src/modules/app/actions';
import { OrdersApis } from 'src/server/api/Orders';

function* order({ payload }: ActionType<typeof OrderActions.order.request>): SagaIterator {
  try {
    console.log('order sSaga');
    yield* put(AppActions.insureToken()) as any;
    const { data } = yield* call(OrdersApis.order, {id: payload}) as any;
    console.log('order response:');
    console.log(data);
    yield* put(OrderActions.order.success({ order: data.data.order, products: data.data.items, billingAddress: data.data.billing_address, shippingAddress: data.data.shipping_address})) as any;
  } catch (error) {
    const errorMessage = (error as any).response.data.message;
    yield* put(OrderActions.order.fail(errorMessage)) as any;
  }
}

function* accept({ payload }: ActionType<typeof OrderActions.accept.request>): SagaIterator {
  try {
    console.log('accept order saga');
    yield* put(AppActions.insureToken()) as any;

    // Accept order
    const { data } = yield* call(OrdersApis.accept, payload) as any;
    console.log('accept order response:');
    console.log(data);
    yield* put(OrderActions.accept.success()) as any;

    // yield* put(OrderActions.order.request(payload.id)) as any;
    // Fetch order details again
    // const order = yield* call(OrdersApis.order, {id: payload.id}) as any;
    // console.log('order response again')
    // console.log(order.data)
    // yield* put(OrderActions.order.success({ order: order.data.data.order, products: order.data.data.items, billingAddress: data.data.billing_address, shippingAddress: data.data.shipping_address})) as any;
  } catch (error) {
    // const errorMessage = (error as any).response.data.message;
    console.log("accept order error");
    console.log((error as any).response.data);
    yield* put(OrderActions.accept.fail("Something went wrong while trying to accept the order")) as any;
  }
}

function* reject({ payload }: ActionType<typeof OrderActions.reject.request>): SagaIterator {
  try {
    console.log('reject order saga');
    yield* put(AppActions.insureToken()) as any;

    // Accept order
    const { data } = yield* call(OrdersApis.reject, payload) as any;
    console.log('reject order response:');
    console.log(data);
    yield* put(OrderActions.reject.success()) as any;

    // Fetch order details again
    // yield* put(OrderActions.order.request(payload.id)) as any;
    // const order = yield* call(OrdersApis.order, {id: payload.id}) as any;
    // console.log('order response')
    // console.log(order.data)
    // yield* put(OrderActions.order.success({ order: order.data.data.order, products: order.data.data.items, billingAddress: data.data.billing_address, shippingAddress: data.data.shipping_address})) as any;
  } catch (error) {
    //const errorMessage = (error as any).response.data.message;
    console.log("reject order error");
    console.log((error as any).response);
    yield* put(OrderActions.reject.fail("Something went wrong while trying to reject the order")) as any;
  }
}

function* complete({ payload }: ActionType<typeof OrderActions.complete.request>): SagaIterator {
  try {
    console.log('complete order saga');
    yield* put(AppActions.insureToken()) as any;

    // Accept order
    const { data } = yield* call(OrdersApis.complete, payload) as any;
    console.log('complete order response:');
    console.log(data);
    yield* put(OrderActions.complete.success()) as any;

    // Fetch order details again
    // yield* put(OrderActions.order.request(payload.id)) as any;
    // const order = yield* call(OrdersApis.order, {id: payload.id}) as any;
    // console.log('order response')
    // console.log(order.data)
    // yield* put(OrderActions.order.success({ order: order.data.data.order, products: order.data.data.items, billingAddress: data.data.billing_address, shippingAddress: data.data.shipping_address})) as any;
  } catch (error) {
    // const errorMessage = (error as any).response.data.message;
    console.log("complete order error");
    // console.log((error as any).response.data);
    yield* put(OrderActions.complete.fail("Something went wrong while trying to complete the order")) as any;
  }
}


export function* watchOrder(): SagaIterator {
  yield* takeLatest(OrderActions.order.request, order) as any;
  yield* takeLatest(OrderActions.accept.request, accept) as any;
  yield* takeLatest(OrderActions.reject.request, reject) as any;
  yield* takeLatest(OrderActions.complete.request, complete) as any;
}