import { BEST_SELLERS_URL, BRANDS_URL, CREATE_SLIDER_URL, DELETE_SLIDER_URL, FLASH_SELLS_URL, PRODUCTS_URL, SIMPLE_PRODUCT_URL, SLIDER_URL, YOU_MAY_LIKE_URL } from '../../configs';
import { Api } from './Api';

export class SliderApis {
  static slider(): ReturnType<typeof Api.get> {
    return Api.get(SLIDER_URL);
  }

  static create(payload): ReturnType<typeof Api.post> {
    return Api.post(CREATE_SLIDER_URL, payload);
  }

  static delete(payload): ReturnType<typeof Api.post> {
    return Api.post(DELETE_SLIDER_URL, payload);
  }
}