import { ActionType, getType } from 'deox';
import { SagaIterator } from 'redux-saga';
import { put, takeLatest, call, select } from 'typed-redux-saga';

import './actions';
import axios from 'axios';
import { HomeApis } from '../../../server/api/Home';
import { UsersActions } from './actions';
import { ProductsApis } from '../../../server/api/Products';
import { AppActions } from '../../../modules/app/actions';
import { AppApis } from '../../../server/api/App';
import { CouponsApis } from 'src/server/api/Coupons';
import { SliderApis } from 'src/server/api/Slider';
import { UsersApis } from 'src/server/api/Users';


function* users({ }: ActionType<typeof UsersActions.users.request>): SagaIterator {
  try {
    // Fetching Categories
    yield* put(AppActions.insureToken()) as any;
    
    const { data } = yield* call(UsersApis.users) as any;
    console.log('users response: ');
    console.log(data);
    yield* put(UsersActions.users.success(data.data)) as any;
  } catch (error) {
    console.log(error);
    yield* put(UsersActions.users.fail('Something went wrong while trying to load coupons')) as any;
  }
}

function* createUser({ payload }: ActionType<typeof UsersActions.createUser.request>): SagaIterator {
  try {
    // Fetching Categories
    yield* put(AppActions.insureToken()) as any;
    
    const { data } = yield* call(UsersApis.createUser, payload) as any;
    console.log('users response: ');
    console.log(data);
    yield* put(UsersActions.createUser.success()) as any;
    yield* put(UsersActions.users.request()) as any;
  } catch (error) {
    console.log(error);
    const errorMessage = (error as any).response.data.message;
    yield* put(UsersActions.createUser.fail(errorMessage)) as any;
  }
}

export function* watchUsers(): SagaIterator {
  yield* takeLatest(UsersActions.users.request, users) as any;
  yield* takeLatest(UsersActions.createUser.request, createUser) as any;
}
