import { createReducer } from 'deox';
import { produce } from 'immer';

import {
  CategoriesActions,
} from './actions';
import { AttributeModel } from '../../../server/models/attribute';
import { CategoriesTypes } from './types';

const initialState: CategoriesTypes = {
  categories: [],
  loading: false,
  loadingCreateCategory: false,
  createCategoryModal: false,
  createCategoryModalName: '',
  createCategoryModalNameAr: '',
  createCategoryErrorMessage: ''
};

export const categoriesReducer = createReducer(initialState, handleAction => [
  handleAction(CategoriesActions.categories.request, (state) =>
    produce(state, draft => {
      draft.loading = true;
      draft.categories = [];
    }),
  ),
  handleAction(CategoriesActions.categories.success, (state, { payload }) =>
    produce(state, draft => {
      draft.loading = false;
      draft.categories = payload;
    }),
  ),
  handleAction(CategoriesActions.categories.fail, (state, { payload }: any) =>
    produce(state, draft => {
      draft.loading = false;
    }),
  ),

  // Create User
  handleAction(CategoriesActions.create.request, (state, payload) =>
    produce(state, draft => {
      draft.loadingCreateCategory = true;
    }),
  ),
  handleAction(CategoriesActions.create.success, (state) =>
    produce(state, draft => {
      draft.loadingCreateCategory = false;
      draft.createCategoryModal = false
    }),
  ),
  handleAction(CategoriesActions.create.fail, (state, { payload }: any) =>
    produce(state, draft => {
      draft.createCategoryErrorMessage = payload
      draft.loadingCreateCategory = false;
    }),
  ),

  // Create User Modal
  handleAction(CategoriesActions.createCategoryModel, (state, { payload }: any) =>
    produce(state, draft => {
      draft.createCategoryModal = payload;
      draft.createCategoryModalName = ''
    }),
  ),
  
  // Fields
  handleAction(CategoriesActions.setCreateCategoryModelName, (state, { payload }: any) =>
    produce(state, draft => {
      draft.createCategoryModalName = payload;
    }),
  ),

  handleAction(CategoriesActions.setCreateCategoryModelNameAr, (state, { payload }: any) =>
    produce(state, draft => {
      draft.createCategoryModalNameAr = payload;
    }),
  ),
  
  handleAction(CategoriesActions.reset, (state, { payload }: any) =>
    produce(state, draft => {
      draft.loadingCreateCategory = false
      draft.createCategoryModal = false
      draft.createCategoryModalName = ''
      draft.createCategoryModalNameAr = ''
      draft.createCategoryErrorMessage = ''
    }),
  ),
]);
