import { Api } from './Api';
import {  getUrl } from '../../utils/helper';
import { CATEGORIES_URL, CREATE_CATEGORY_URL } from '../../configs';

export class AppApis {
  static categories(): ReturnType<typeof Api.get> {
    return Api.get(CATEGORIES_URL);
  }
  
  static createCategories(payload): ReturnType<typeof Api.post> {
    return Api.post(CREATE_CATEGORY_URL, payload);
  }
}
