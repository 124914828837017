import { createAction } from 'deox';
import { BrandModel } from 'src/server/models/brand';
import { CreateBrandModel } from 'src/server/models/create_brand';


export class BrandsActions {
  static brands = {
    request: createAction('brands/brands_request'),
    success: createAction('brands/brands_success', resolve => (payload: BrandModel[]) =>
      resolve(payload),
    ),
    fail: createAction('brands/brands_failure', resolve => (error: any) =>
      resolve(error),
    ),
  };

  static create = {
    request: createAction('brands/create_brand_request', resolve => (payload: CreateBrandModel) =>
      resolve(payload),
    ),
    success: createAction('brands/create_brand_success'),
    fail: createAction('brands/create_brand_failure', resolve => (error: any) =>
      resolve(error),
    ),
  };

  static createBrandModel = createAction('brands/create_brand_model', resolve => (payload: boolean) =>
    resolve(payload),
  );

  static setCreateBrandModelName = createAction('brands/create_brand_model_name', resolve => (payload: String) =>
    resolve(payload),
  );
  
  static setCreateBrandModelNameAr = createAction('brands/create_category_model_name_ar', resolve => (payload: String) =>
    resolve(payload),
  );

  static setCreateBrandModelImage = createAction('brands/create_category_model_image', resolve => (payload: String) =>
    resolve(payload),
  );

  static setCreateBrandModelBannerImage = createAction('brands/create_category_model_banner_image', resolve => (payload: String) =>
    resolve(payload),
  );

  static reset = createAction('categories/reset');
}