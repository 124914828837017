import { createReducer } from 'deox';
import { produce } from 'immer';

import {
  BrandsActions,
} from './actions';
import { AttributeModel } from '../../../server/models/attribute';
import { BrandsTypes } from './types';

const initialState: BrandsTypes = {
  brands: [],
  loading: false,
  loadingCreateBrand: false,
  createBrandModal: false,
  createBrandModalName: '',
  createBrandModalNameAr: '',
  createBrandErrorMessage: '',
  createBrandModalImage: '',
  createBrandModalBannerImage: ''
};

export const brandsReducer = createReducer(initialState, handleAction => [
  handleAction(BrandsActions.brands.request, (state) =>
    produce(state, draft => {
      draft.loading = true;
      draft.brands = [];
    }),
  ),
  handleAction(BrandsActions.brands.success, (state, { payload }) =>
    produce(state, draft => {
      draft.loading = false;
      draft.brands = payload;
    }),
  ),
  handleAction(BrandsActions.brands.fail, (state, { payload }: any) =>
    produce(state, draft => {
      draft.loading = false;
    }),
  ),

  // Create User
  handleAction(BrandsActions.create.request, (state, payload) =>
    produce(state, draft => {
      draft.loadingCreateBrand = true;
    }),
  ),
  handleAction(BrandsActions.create.success, (state) =>
    produce(state, draft => {
      draft.loadingCreateBrand = false;
      draft.createBrandModal = false
    }),
  ),
  handleAction(BrandsActions.create.fail, (state, { payload }: any) =>
    produce(state, draft => {
      draft.createBrandErrorMessage = payload
      draft.loadingCreateBrand = false;
    }),
  ),

  // Create User Modal
  handleAction(BrandsActions.createBrandModel, (state, { payload }: any) =>
    produce(state, draft => {
      draft.createBrandModal = payload;
      draft.createBrandModalNameAr = ''
      draft.createBrandModalName = ''
      draft.createBrandModalImage = ''
      draft.createBrandModalBannerImage = ''
      draft.createBrandErrorMessage = ''
    }),
  ),
  
  // Fields
  handleAction(BrandsActions.setCreateBrandModelName, (state, { payload }: any) =>
    produce(state, draft => {
      draft.createBrandModalName = payload;
    }),
  ),

  handleAction(BrandsActions.setCreateBrandModelNameAr, (state, { payload }: any) =>
    produce(state, draft => {
      draft.createBrandModalNameAr = payload;
    }),
  ),

  handleAction(BrandsActions.setCreateBrandModelImage, (state, { payload }: any) =>
    produce(state, draft => {
      draft.createBrandModalImage = payload;
    }),
  ),

  handleAction(BrandsActions.setCreateBrandModelBannerImage, (state, { payload }: any) =>
    produce(state, draft => {
      draft.createBrandModalBannerImage = payload;
    }),
  ),
  
  
  handleAction(BrandsActions.reset, (state, { payload }: any) =>
    produce(state, draft => {
      draft.loadingCreateBrand = false
      draft.createBrandModal = false
      draft.createBrandModalName = ''
      draft.createBrandModalNameAr = ''
      draft.createBrandErrorMessage = ''
    }),
  ),
]);
