import { Card } from '@mui/material';
import { CryptoOrder } from 'src/models/crypto_order';
import RecentOrdersTable from './ProductsTable';
import { subDays } from 'date-fns';
import { OrderModel } from 'src/server/models/order';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/rootState';
import { LocalDiningRounded } from '@mui/icons-material';
import SuspenseLoader from 'src/components/SuspenseLoader';
import ProductsTable from './ProductsTable';

function Products() {
  const { loading } = useSelector((state: RootState) => state.products);

  if(loading) {
    return <div>Loading...</div>
  }

  return (
    <Card>
      { !loading && <ProductsTable/>}
    </Card>
  );
}

export default Products;
