import { createReducer } from 'deox';
import { produce } from 'immer';

import {
  SliderActions,
} from './actions';
import { SliderTypes } from './types';

const initialState: SliderTypes = {
  loading: false,
  sliders: [],
  loadingCreateSlider: false,
  createSliderModal: false,
  createSliderModalLink: '',
  createSliderModalImage: '',
  createSliderErrorMessage: '',
  deleteModal: {
    loading: false,
    show: false,
    error: ''
  }
};

export const sliderReducer = createReducer(initialState, handleAction => [
  handleAction(SliderActions.sliders.request, (state, payload) =>
    produce(state, draft => {
      draft.loading = true;
    }),
  ),
  handleAction(SliderActions.sliders.success, (state, { payload }) =>
    produce(state, draft => {
      draft.loading = false;
      draft.sliders = payload;
    }),
  ),
  handleAction(SliderActions.sliders.fail, (state, { payload }: any) =>
    produce(state, draft => {
      draft.loading = false;
    }),
  ),

  // Create Slider
  handleAction(SliderActions.create.request, (state, payload) =>
    produce(state, draft => {
      draft.loadingCreateSlider = true;
    }),
  ),
  handleAction(SliderActions.create.success, (state) =>
    produce(state, draft => {
      draft.loadingCreateSlider = false;
      draft.createSliderModal = false
    }),
  ),
  handleAction(SliderActions.create.fail, (state, { payload }: any) =>
    produce(state, draft => {
      draft.createSliderErrorMessage = payload
      draft.loadingCreateSlider = false;
    }),
  ),

  // Create User Modal
  handleAction(SliderActions.createSliderModel, (state, { payload }: any) =>
    produce(state, draft => {
      draft.createSliderModal = payload;
      draft.createSliderModalImage = ''
      draft.createSliderModalLink = ''
      draft.createSliderErrorMessage = ''
    }),
  ),
  
  // Fields
  handleAction(SliderActions.setCreateSliderModelLink, (state, { payload }: any) =>
    produce(state, draft => {
      draft.createSliderModalLink = payload;
    }),
  ),

  handleAction(SliderActions.setCreateSliderModelImage, (state, { payload }: any) =>
    produce(state, draft => {
      draft.createSliderModalImage = payload;
    }),
  ),
  
  handleAction(SliderActions.reset, (state) =>
    produce(state, draft => {
      draft.loadingCreateSlider = false
      draft.createSliderModal = false
      draft.createSliderModalImage = ''
      draft.createSliderModalLink = ''
      draft.createSliderErrorMessage = ''
    }),
  ),

  // Delete Slider Modal
  handleAction(SliderActions.deleteSliderModal, (state, { payload }: any) =>
    produce(state, draft => {
      draft.deleteModal = payload;
    }),
  ),

  // Delete Slider
  handleAction(SliderActions.delete.request, (state, payload) =>
    produce(state, draft => {
      draft.deleteModal = {...draft.deleteModal, error: '', loading: true, show: true}
    }),
  ),
  handleAction(SliderActions.delete.success, (state) =>
    produce(state, draft => {
      draft.deleteModal = {...draft.deleteModal, error: '', loading: false, show:false}
    }),
  ),
  handleAction(SliderActions.delete.fail, (state, { payload }: any) =>
    produce(state, draft => {
      draft.deleteModal = {...draft.deleteModal, error: payload, loading: false}
    }),
  ),
]);
