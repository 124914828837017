import { createReducer } from 'deox';
import { produce } from 'immer';

import {
  ProductsActions,
} from './actions';
import { ProductsTypes } from './types';
import { AttributeModel } from '../../../server/models/attribute';

const initialState: ProductsTypes = {
  products: [],
  attributes: {},
  total_count: 0,
  page: 0,
  loading: false,
  loadingProducts: false,
  view: 'grid',
  payload: {
    id: '',
    name: '',
    min_price: 0,
    max_price: '',
    attrs: [],
    page: 0
  }
};

export const productsReducer = createReducer(initialState, handleAction => [
  handleAction(ProductsActions.products.request, (state, { payload }) =>
    produce(state, draft => {
      console.log('requesting sliders');
      draft.payload = payload;
      draft.page = payload.page;
      draft.loadingProducts = true;
    }),
  ),
  handleAction(ProductsActions.products.success, (state, { payload }) =>
    produce(state, draft => {
      draft.loadingProducts = false;
      // Products
      draft.products = payload.products;
      // Total count
      draft.total_count = +payload.total_count;
      // Attributes
      try {
        //var result: Map<string, AttributeModel[]> = new Map<string, AttributeModel[]>([]);
        var result = {};
        payload.attributes.forEach(function (attribute) {
          //result.set(attribute.attr_name_en, [...(result.get(attribute.attr_name_en) ?? []), attribute])
          result[attribute.attr_name_en] = [...(result[attribute.attr_name_en] ?? []), attribute];
        });
        draft.attributes = result;
        console.log('#attributes'); 
        console.log(result);
      } catch (exception) {
        console.log(exception)
      }
    }),
  ),
  handleAction(ProductsActions.products.fail, (state, { payload }: any) =>
    produce(state, draft => {
      draft.loadingProducts = false;
    }),
  ),
]);
