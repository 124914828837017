import { ActionType, getType } from 'deox';
import { SagaIterator } from 'redux-saga';
import { put, takeLatest, call, select } from 'typed-redux-saga';
import './actions';
import axios from 'axios';
import { HomeApis } from '../../../server/api/Home';
import { SliderActions } from './actions';
import { ProductsApis } from '../../../server/api/Products';
import { AppActions } from '../../../modules/app/actions';
import { AppApis } from '../../../server/api/App';
import { CouponsApis } from 'src/server/api/Coupons';
import { SliderApis } from 'src/server/api/Slider';


function* sliders({ }: ActionType<typeof SliderActions.sliders.request>): SagaIterator {
  try {
    // Fetching Categories
    yield* put(AppActions.insureToken()) as any;
    
    const { data } = yield* call(SliderApis.slider) as any;
    console.log('sliders response: ');
    console.log(data);
    yield* put(SliderActions.sliders.success(data.data)) as any;
  } catch (error) {
    console.log(error);
    yield* put(SliderActions.sliders.fail('Something went wrong while trying to load coupons')) as any;
  }
}

function* create({ payload }: ActionType<typeof SliderActions.create.request>): SagaIterator {
  try {
    // Fetching Categories
    yield* put(AppActions.insureToken()) as any;
    
    const { data } = yield* call(SliderApis.create, payload) as any;
    console.log('create slider response: ');
    console.log(data);
    yield* put(SliderActions.create.success()) as any;

    const slidersResponse = yield* call(SliderApis.slider) as any;
    yield* put(SliderActions.sliders.success(slidersResponse.data.data)) as any;
  } catch (error) {
    console.log(error);
    yield* put(SliderActions.create.fail('Something went wrong while trying to load coupons')) as any;
  }
}

function* deleteSlider({ payload }: ActionType<typeof SliderActions.delete.request>): SagaIterator {
  try {
    // Fetching Categories
    yield* put(AppActions.insureToken()) as any;
    
    const { data } = yield* call(SliderApis.delete, payload) as any;
    console.log('delete slider response: ');
    console.log(data);
    yield* put(SliderActions.delete.success()) as any;

    const slidersResponse = yield* call(SliderApis.delete, payload) as any;
    yield* put(SliderActions.delete.success()) as any;
  } catch (error) {
    console.log(error);
    yield* put(SliderActions.delete.fail('Something went wrong while trying to load coupons')) as any;
  }
}

export function* watchSlider(): SagaIterator {
  yield* takeLatest(SliderActions.sliders.request, sliders) as any;
  yield* takeLatest(SliderActions.create.request, create) as any;
  yield* takeLatest(SliderActions.delete.request, deleteSlider) as any;
}
