import { Helmet } from 'react-helmet-async';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import { Grid, Container } from '@mui/material';
import Footer from 'src/components/Footer';
import { useEffect, useState } from 'react';
import { RootState } from 'src/store/rootState';
import { useSelector } from 'react-redux';
import { useAction } from 'src/utils/hooks/action';
import OrderHeader from './components/DeviceRequestsHeader';
import DeviceRequestsHeader from './components/DeviceRequestsHeader';

function DeviceRequests() {

  // if(orderAccepted || orderRejected || orderCompleted) {
  //   return <>Processing, Please wait</>
  // }
  
  return (
    <>
      <Helmet>
        <title>Device Requests</title>
      </Helmet>
      <PageTitleWrapper>
        <DeviceRequestsHeader title={'Device Requests'} description={''} />
      </PageTitleWrapper>

      <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            
          </Grid>
        </Grid>

        <div style={{height: 20}}/>

        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
          </Grid>
        </Grid>
      <Footer />
    </>
  );
}

export default DeviceRequests;
