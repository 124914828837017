import { createAction } from 'deox';
import { SliderModel } from '../../../server/models/slider';
import { ProductModel } from '../../../server/models/product';
import { AttributeModel } from '../../../server/models/attribute';
import { ProductsPayload } from '../../../server/payload/products';
import { CouponModel } from 'src/server/models/coupon';
import { CreateCouponModel } from 'src/server/models/create_coupon';
import CreateCouponModal from '../components/CreateCouponModal';

export class CouponsActions {
  static coupons = {
    request: createAction('coupons/coupons_request'),
    success: createAction('coupons/coupons_success', resolve => (payload: CouponModel[]) =>
      resolve(payload),
    ),
    fail: createAction('coupons/coupons_failure', resolve => (error: any) =>
      resolve(error),
    ),
  };

  static create = {
    request: createAction('coupons/coupon_create_request', resolve => (payload: CreateCouponModel) =>
      resolve(payload),
    ),
    success: createAction('coupons/coupon_create_success'),
    fail: createAction('coupons/coupon_create_failure', resolve => (error: any) =>
      resolve(error),
    ),
  };
  
  static createCouponModal = createAction('coupon/create_coupon', resolve => (payload: CreateCouponModel) => resolve(payload),);
  
  static reset = createAction('coupon/reset');

}