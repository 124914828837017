import { createAction } from 'deox';
import { SliderModel } from '../../../server/models/slider';
import { ProductModel } from '../../../server/models/product';
import { AttributeModel } from '../../../server/models/attribute';
import { ProductsPayload } from '../../../server/payload/products';
import { CategoryModel } from 'src/server/models/category';
import { CouponModel } from 'src/server/models/coupon';
import { CouponPayload } from 'src/server/payload/copoun';
import { AssignCategoryToCoupon } from 'src/server/models/assign_category_to_coupon';
import { AssignProductToCoupon } from 'src/server/models/assign_product_to_coupon';
import { AssignCategoryToCouponPayload } from 'src/server/payload/assign_category_to_coupon';
import { AssignProductToCouponPayload } from 'src/server/payload/assign_product_to_coupon';


export class CouponActions {
  static coupon = {
    request: createAction('coupon/coupon_request', resolve => (payload: CouponPayload) =>
      resolve(payload),
    ),
    
    success: createAction('coupon/coupon_success', resolve => (payload: { coupon: CouponModel, products: ProductModel[], categories: CategoryModel[] }) =>
      resolve(payload),
    ),

    fail: createAction('coupon/coupon_failure', resolve => (error: any) =>
      resolve(error),
    ),
  };

  // Fetch available categories
  static categoriesToAssign = {
    request: createAction('coupon/categories_to_assign_request'),
    
    success: createAction('coupon/categories_to_assign_success', resolve => (payload: CategoryModel[]) =>
      resolve(payload),
    ),

    fail: createAction('coupon/categories_to_assign_failure', resolve => (error: any) =>
      resolve(error),
    ),
  };

  // Update Assign Category Modal State
  static assignCategoryToCouponModal = createAction('coupon/assign_category_to_coupon_modal', resolve => (payload: AssignCategoryToCoupon) => resolve(payload));

  // Assign category to coupon
  static assignCategory = {
    request: createAction('coupon/assign_category_request', resolve => (payload: AssignCategoryToCouponPayload) =>
      resolve(payload),
    ),
    
    success: createAction('coupon/assign_category_success'),

    fail: createAction('coupon/assign_category_failure', resolve => (error: any) =>
      resolve(error),
    ),
  };
  
  // Update Assign Product Modal State
  static assignProductToCouponModal = createAction('coupon/assign_product_to_coupon_modal', resolve => (payload: AssignProductToCoupon) => resolve(payload));

  // Assign category to coupon
  static assignProduct = {
    request: createAction('coupon/assign_product_request', resolve => (payload: AssignProductToCouponPayload) =>
      resolve(payload),
    ),
    
    success: createAction('coupon/assign_product_success'),
    
    fail: createAction('coupon/assign_product_failure', resolve => (error: any) =>
      resolve(error),
    ),
  };

  // Update Assign Category Modal State
  static showAssignCategoryModal = createAction('coupon/show_assign_category_to_coupon_modal', resolve => (payload: boolean) => resolve(payload));
  
  // Update Assign Product Modal State
  static showAssignProductModal = createAction('coupon/show_assign_product_to_coupon_modal', resolve => (payload: boolean) => resolve(payload));

}
