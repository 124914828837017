import { createReducer } from 'deox';
import { produce } from 'immer';

import {
  OrdersActions,
} from './actions';
import { OrdersTypes } from './types';

const initialState: OrdersTypes = {
  loading: false,
  error: undefined,
  page: 0,
  limit: 10,
  orders: [],
  count: 0,
  status: 'Waiting For Payment'
};

export const ordersReducer = createReducer(initialState, handleAction => [
  // Login
  handleAction(OrdersActions.orders.request, (state, { payload } ) =>
    produce(state, draft => {
      console.log('requesting orders');
      draft.loading = true;
      draft.page = payload.pagination.page;
      draft.limit = payload.pagination.limit;
      draft.orders = [];
      draft.status = payload.status;
    }),
  ),
  handleAction(OrdersActions.orders.success, (state, { payload }: any) =>
    produce(state, draft => {
      draft.loading = false;
      draft.error = undefined;
      draft.orders = payload.orders;
      draft.count = payload.count;
    }),
  ),
  handleAction(OrdersActions.orders.fail, (state, { payload }: any) =>
    produce(state, draft => {
      console.log('orders failed')
      draft.loading = false;
      draft.error = payload;
    }),
  ),
]);