import { FC, ChangeEvent, useState } from 'react';
import { format } from 'date-fns';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import {
  Tooltip,
  Divider,
  Box,
  FormControl,
  InputLabel,
  Card,
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableContainer,
  Select,
  MenuItem,
  Typography,
  useTheme,
  CardHeader,
  Button
} from '@mui/material';

import Label from 'src/components/Label';
import { CryptoOrder, CryptoOrderStatus } from 'src/models/crypto_order';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';;
import { OrderModel } from 'src/server/models/order';
import { OrderStatus } from 'src/server/models/order_status';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/rootState';
import { Navigate, useNavigate } from 'react-router';
import { ProductsActions } from '../module/actions';
import { useAction } from 'src/utils/hooks/action';
import { Link } from 'react-router-dom';

interface ProductsTableProps {
  className?: string;
}

interface Filters {
  status?: CryptoOrderStatus;
}

const getStatusLabel = (status: OrderStatus): JSX.Element => {
  var color :
  | 'primary'
  | 'black'
  | 'secondary'
  | 'error'
  | 'warning'
  | 'success'
  | 'info' = 'black';
  if(status == "Waiting For Payment") {
    color = 'warning';
  }
  if(status == "Pending") {
    color = 'secondary';
  }
  if(status == 'Processing') {
    color = 'info';
  }
  if(status == 'Completed') {
    color = 'success';
  }
  if(status == 'Refunded') {
    color = "error"
  }
  if(status == 'Failed' || status == 'Cancelled') {
    color = 'error';
  }

  return <div style={{width:150}}><Label color={color}>{status}</Label></div>;
};

const ProductsTable: FC<ProductsTableProps> = ({  }) => {
  const { products, page, total_count, payload } = useSelector((state: RootState) => state.products);
  const productsAction = useAction(ProductsActions.products.request);
  const navigate = useNavigate();
  

  // const statusOptions = [
  //   {
  //     id: 'all',
  //     name: 'All'
  //   },
  //   {
  //     id: 'completed',
  //     name: 'Completed'
  //   },
  //   {
  //     id: 'pending',
  //     name: 'Pending'
  //   },
  //   {
  //     id: 'failed',
  //     name: 'Failed'
  //   }
  // ];

  // const handleStatusChange = (e: ChangeEvent<HTMLInputElement>): void => {
  //   let value = null;

  //   if (e.target.value !== 'all') {
  //     value = e.target.value;
  //   }
  // };

  // const handleSelectAllCryptoOrders = (
  //   event: ChangeEvent<HTMLInputElement>
  // ): void => {
  //   setSelectedCryptoOrders(
  //     event.target.checked
  //       ? (orders ?? []).map((cryptoOrder) => cryptoOrder.id)
  //       : []
  //   );
  // };


  const handlePageChange = (event: any, page: number): void => {
    productsAction({ ...payload, page: page });
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    //setLimit(parseInt(event.target.value));
  };

  const theme = useTheme();

  return (
    <Card>
      {/* {true && (
        <Box flex={1} p={2}>
          <BulkActions />
        </Box>
      )} */}
      {true && (
        <CardHeader
        style={{background: '#11192A', color:'white'}}
          // action={
          //   <Box width={150}>
          //     <FormControl fullWidth variant="outlined">
          //       <InputLabel>Status</InputLabel>
          //       <Select
          //         value={filters.status || 'all'}
          //         onChange={handleStatusChange}
          //         label="Status"
          //         autoWidth
          //       >
          //         {statusOptions.map((statusOption) => (
          //           <MenuItem key={statusOption.id} value={statusOption.id}>
          //             {statusOption.name}
          //           </MenuItem>
          //         ))}
          //       </Select>
          //     </FormControl>
          //   </Box>
          // }
          title={"Products " + '(' + total_count + ')'}
        />
      )}
      <Divider />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left">Id</TableCell>
              <TableCell align="left">Availability</TableCell>
              <TableCell align="left">Image</TableCell>
              <TableCell align="left">SKU</TableCell>
              <TableCell align="left">Barcode</TableCell>
              <TableCell align="left">Arabic Name</TableCell>
              <TableCell align="left">English Name </TableCell>
              <TableCell align="left">Regular Price</TableCell>
              <TableCell align="left">Sale Price</TableCell>
              <TableCell align="left">Description</TableCell>
              <TableCell align="left">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(products ?? []).map((product) => {
              return (
                <TableRow
                  hover
                  key={product.id}
                  selected={false}
                >
                  <TableCell>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      <a href={`#/product/${product?.id}`}>#{product.id}</a>
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Label color={product.is_available  == 1 ? 'success' : 'error'}>{product.is_available == 1 ? "Available" : "Unavailable"}</Label>
                  </TableCell>
                  
                  <TableCell>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      <div style={{border: '1px solid #f1f1f1f1', borderRadius: 4}}><img style={{width: 50, height: 50}} src={product.image}/></div>
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {product.sku}
                    </Typography>
                  </TableCell>

                  <TableCell align="left">
                    {product.barcode ?? '-'}
                  </TableCell>

                  <TableCell>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {product.name}
                    </Typography>
                  </TableCell>

                  <TableCell align="left">
                    <Typography variant="body2" color="text.secondary" noWrap>
                      {product.name_en}
                    </Typography>
                  </TableCell>

                  <TableCell align="left">
                    <Typography
                      variant="body1"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      AED {product.regular_price}
                    </Typography>
                  </TableCell>

                  <TableCell align="left">
                    <Typography
                      variant="body1"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      AED {product.sale_price}
                    </Typography>
                  </TableCell>
                  
                  <TableCell align="left">
                    <Typography
                      variant="body1"
                      color="text.primary"
                      gutterBottom
                      noWrap
                    >
                      {(product.description == '0' ? '-' : product.description) ?? '-'}
                    </Typography>
                  </TableCell>

                  <TableCell align="left">
                    <Tooltip title={"View Product #" + product.id} arrow>
                    <Button variant="outlined"
                      onClick={() => {
                        navigate({
                          pathname: `/product/${product?.id}`
                        })
                      }}
                        sx={{
                          '&:hover': {
                            background: theme.colors.primary.lighter
                          },
                          color: theme.palette.primary.main,
                          width: 80
                        }}
                        color="inherit"
                        size="small"
                      >
                        <Link to={`/product/${product?.id}`}>
                          View
                        </Link>
                      </Button>
                    </Tooltip>
                  </TableCell>


                  {/* <TableCell align="left">
                    <Tooltip title="Edit product" arrow>
                      <IconButton
                        sx={{
                          '&:hover': {
                            background: theme.colors.primary.lighter
                          },
                          color: theme.palette.primary.main
                        }}
                        color="inherit"
                        size="small"
                      >
                        <EditTwoToneIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete product" arrow>
                      <IconButton
                        sx={{
                          '&:hover': { background: theme.colors.error.lighter },
                          color: theme.palette.error.main
                        }}
                        color="inherit"
                        size="small"
                      >
                        <DeleteTwoToneIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </TableCell> */}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box p={2}>
        <TablePagination
          component="div"
          count={total_count}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={40}
          rowsPerPageOptions={[5, 10, 25, 30]}
        />
      </Box>
    </Card>
  );
};

export default ProductsTable;
