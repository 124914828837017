import { Api } from './Api';
import { getUrl } from '../../utils/helper';
import { ACCEPT_ORDER_URL, CANCEL_ORDER_URL, COMPLETE_ORDER_URL, ORDERS_URL, ORDER_URL, REJECT_ORDER_URL } from '../../configs';
import { OrderPayload } from '../payload/order';
import { CancelOrderPayload } from '../payload/cancel_order';
import { OrdersPayload } from '../payload/orders';
import { AcceptOrderPayload } from '../payload/accept_order';
import { RejectOrderPayload } from '../payload/reject_order';
import { CompleteOrderPayload } from '../payload/complete_order';


export class OrdersApis {
  static orders(payload: OrdersPayload): ReturnType<typeof Api.get> {
    return Api.get(ORDERS_URL, {"page": payload.pagination.page, "limit": payload.pagination.limit, "status": payload.status});
  }

  static order(payload: OrderPayload): ReturnType<typeof Api.get> {
    return Api.get(ORDER_URL, payload);
  }

  static accept(payload: AcceptOrderPayload): ReturnType<typeof Api.get> {
    return Api.post(ACCEPT_ORDER_URL, payload);
  }

  static reject(payload: RejectOrderPayload): ReturnType<typeof Api.get> {
    return Api.post(REJECT_ORDER_URL, payload);
  }

  static complete(payload: CompleteOrderPayload): ReturnType<typeof Api.get> {
    return Api.post(COMPLETE_ORDER_URL, payload);
  }
}