import { Api } from './Api';
import { getUrl } from '../../utils/helper';
import { PRODUCTS_URL, PRODUCT_URL, ADD_PRODUCT_IMAGE_URL, DELETE_PRODUCT_IMAGE_URL, DEFAULT_PRODUCT_IMAGE_URL, ASSIGN_CATEGORY_TO_PRODUCT_URL, REMOVE_ASSIGNED_PRODUCT_CATEGORY_URL, UPDATE_PRODUCT_DETAILS_URL } from '../../configs';
import { UploadProductImagePayload } from '../payload/upload_product_image';
import { DeleteProductImagePayload } from '../payload/delete_product_image';
import { DefaultProductImagePayload } from '../payload/default_product_image';
import { AssignCategoryToProductPayload } from '../payload/assign_category_to_product';
import { RemoveAssignedProductCategoryPayload } from '../payload/remove_assigned_product_category';
import { UpdateProductDetailsPayload } from '../payload/update_product_details';


export class ProductsApis {
  static products(payload): ReturnType<typeof Api.get> {
    const params = new URLSearchParams(payload).toString();
    console.log('params')
    console.log(params)

    return Api.get(PRODUCTS_URL, payload);
  }

  static product(payload): ReturnType<typeof Api.get> {
    return Api.get(PRODUCT_URL, payload);
  }

  static uploadImage(payload: UploadProductImagePayload): ReturnType<typeof Api.post> {
    return Api.post(ADD_PRODUCT_IMAGE_URL, payload);
  }

  static deleteImage(payload: DeleteProductImagePayload): ReturnType<typeof Api.post> {
    return Api.post(DELETE_PRODUCT_IMAGE_URL, payload as any);
  }

  static defaultImage(payload: DefaultProductImagePayload): ReturnType<typeof Api.post> {
    return Api.post(DEFAULT_PRODUCT_IMAGE_URL, payload as any);
  }

  static assignCategory(payload: AssignCategoryToProductPayload): ReturnType<typeof Api.post> {
    return Api.post(ASSIGN_CATEGORY_TO_PRODUCT_URL, payload);
  }

  static removeAssignCategory(payload: RemoveAssignedProductCategoryPayload): ReturnType<typeof Api.post> {
    return Api.post(REMOVE_ASSIGNED_PRODUCT_CATEGORY_URL, payload);
  }

  static updateDetails(payload: UpdateProductDetailsPayload): ReturnType<typeof Api.post> {
    return Api.post(UPDATE_PRODUCT_DETAILS_URL, payload);
  }
}
