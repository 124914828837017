import { createAction } from 'deox';
import { SliderModel } from '../../../server/models/slider';
import { ProductModel } from '../../../server/models/product';
import { AttributeModel } from '../../../server/models/attribute';
import { ProductsPayload } from '../../../server/payload/products';
import { CouponModel } from 'src/server/models/coupon';
import { CategoryModel } from 'src/server/models/category';
import { CreateCategoryModel } from 'src/server/models/create_category';


export class CategoriesActions {
  static categories = {
    request: createAction('categories/categories_request'),
    success: createAction('categories/categories_success', resolve => (payload: CategoryModel[]) =>
      resolve(payload),
    ),
    fail: createAction('categories/categories_failure', resolve => (error: any) =>
      resolve(error),
    ),
  };

  static create = {
    request: createAction('categories/create_category_request', resolve => (payload: CreateCategoryModel) =>
      resolve(payload),
    ),
    success: createAction('categories/create_category_success'),
    fail: createAction('categories/create_category_failure', resolve => (error: any) =>
      resolve(error),
    ),
  };

  static createCategoryModel = createAction('categories/create_category_model', resolve => (payload: boolean) =>
    resolve(payload),
  );

  static setCreateCategoryModelName = createAction('categories/create_category_model_name', resolve => (payload: String) =>
    resolve(payload),
  );
  
  static setCreateCategoryModelNameAr = createAction('categories/create_category_model_name_ar', resolve => (payload: String) =>
    resolve(payload),
  );

  static reset = createAction('categories/reset');
}