import { createReducer } from 'deox';
import { produce } from 'immer';
import {
  AppActions,
} from './actions';
import { AppTypes } from './types';
import { Api } from '../../server/api';

const initialState: AppTypes = {
  user: undefined,
  login: false,
  categories: [],
  nav: undefined
};

export const appReducer = createReducer(initialState, handleAction => [
  // Login
  handleAction(AppActions.login, (state, payload) =>
    produce(state, draft => {
      draft.login = true;
    }),
  ),
  // Set user details
  handleAction(AppActions.setUser, (state, { payload }) =>
    produce(state, draft => {
      console.log('trying to cache');
      console.log(payload);
      draft.user = payload;
    }),
  ),

  // Categories
  handleAction(AppActions.categories.request, (state) =>
    produce(state, draft => {
      console.log('request categories redux triggered')
    }),
  ),
  handleAction(AppActions.categories.success, (state, { payload }) =>
    produce(state, draft => {
      draft.categories = payload.categories;
    }),
  ),
  handleAction(AppActions.categories.fail, (state) =>
    produce(state, draft => {
    }),
  ),
  
  handleAction(AppActions.insureToken, (state) =>
    produce(state, draft => {
      try {
        if(draft.user) {
          var token = draft.user.token
          console.log(`token: ${token}`)
          if (token) {
            Api.setAuthToken(token);
          }
        }
      } catch (exception) {
        console.log('insureToken exception')
        console.log(exception)
      }
    }),
  ),

  // Logout
  handleAction(AppActions.logout, (state, payload) =>
    produce(state, draft => {
      draft.user = undefined;
      draft.login = false;
      console.log('logout triggered')
      Api.clearAuthToken();
      draft.nav = "/";
    }),
  ),

  // Navigate to certain route
  handleAction(AppActions.removeNavigate, (state) =>
    produce(state, draft => {
      draft.nav = undefined
    }),
  ),
]);