import { getType } from 'deox';
import { Action, combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage' 

// import { resetStore } from 'modules/app/actions';
// import { authReducer } from 'modules/auth/reducer';
// import { appReducer } from 'modules/app/reducer';
// import { userReducer } from 'modules/user/reducer';
// import { seekerReducer } from 'modules/seeker/reducer';
// import { posterReducer } from 'modules/poster/reducer';

import { RootState } from './rootState';
import { authReducer } from 'src/pages/auth/module/reducer';
import { appReducer } from 'src/modules/app/reducer';
import { ordersReducer } from 'src/pages/orders/module/reducer';
import { orderReducer } from 'src/pages/order/module/reducer';
import { productsReducer } from 'src/pages/products/module/reducer';
import { productReducer } from 'src/pages/product/module/reducer';
import { couponsReducer } from 'src/pages/coupons/module/reducer';
import { couponReducer } from 'src/pages/coupon/module/reducer';
import { sliderReducer } from 'src/pages/sliders/module/reducer';
import { usersReducer } from 'src/pages/users/module/reducer';
import { categoriesReducer } from 'src/pages/categories/module/reducer';
import { brandsReducer } from 'src/pages/brands/module/reducer';
import { attributesReducer } from 'src/pages/attributes/module/reducer';

const rootPersistConfig = {
  key: 'root',
  storage: storage,
  // blacklist: ['auth','app','orders','order','products','product','coupons','coupon'],
  whitelist: ['app']
};

const projectReducer = combineReducers({
  auth: authReducer,
  app: appReducer,
  orders: ordersReducer,
  order: orderReducer,
  products: productsReducer,
  product: productReducer,
  coupons: couponsReducer,
  coupon: couponReducer,
  slider: sliderReducer,
  users: usersReducer,
  categories: categoriesReducer,
  brands: brandsReducer,
  attributes: attributesReducer
});

const rootReducer = (state: RootState | undefined, action: Action) => {
  if (action.type === 'resetStore') {
    state = undefined;
  }
  return projectReducer(state, action);
};

export default persistReducer(rootPersistConfig, rootReducer);
