import { createAction } from 'deox';
import { resolveConfig } from 'prettier';
import { OrderModel } from 'src/server/models/order';
import { LoginRequestPayload } from 'src/server/payload/login';
import { OrdersPayload } from 'src/server/payload/orders';
import { PaginationPayload } from 'src/server/payload/pagination';

export class OrdersActions {
  static orders = {
    request: createAction('orders/orders_request', resolve => (payload: OrdersPayload) =>
      resolve(payload),
    ),
    success: createAction('orders/orders_success', resolve => (payload: {orders: OrderModel[], count: number}) =>
      resolve(payload),
    ),
    fail: createAction('orders/orders_failure', resolve => (error: any) =>
      resolve(error),
    ),
  };
}
