import { createAction } from 'deox';
import { SliderModel } from '../../../server/models/slider';
import { ProductModel } from '../../../server/models/product';
import { AttributeModel } from '../../../server/models/attribute';
import { ProductsPayload } from '../../../server/payload/products';
import { DeleteProductImage } from 'src/server/models/delete_product_image_modal';
import { UploadProductImagePayload } from 'src/server/payload/upload_product_image';
import { DeleteProductImagePayload } from 'src/server/payload/delete_product_image';
import { DefaultProductImagePayload } from 'src/server/payload/default_product_image';
import { CategoryModel } from 'src/server/models/category';
import { AssignCategoryToCoupon } from 'src/server/models/assign_category_to_coupon';
import { AssignCategoryToCouponPayload } from 'src/server/payload/assign_category_to_coupon';
import { AssignCategoryToProductPayload } from 'src/server/payload/assign_category_to_product';
import { AssignCategoryToProduct } from 'src/server/models/assign_category_to_product';
import { RemoveAssignedProductCategoryPayload } from 'src/server/payload/remove_assigned_product_category';
import { UpdateProductDetailsPayload } from 'src/server/payload/update_product_details';


export class ProductActions {
  static reset = createAction('product/reset');
  
  static product = {
    request: createAction('product/product_request', resolve => (payload: { id: string}) =>
      resolve(payload),
    ),
    success: createAction('product/product_success', resolve => (payload: { product: ProductModel }) =>
      resolve(payload),
    ),
    fail: createAction('product/product_failure', resolve => (error: any) =>
      resolve(error),
    ),
  };

  static categories = {
    request: createAction('product/categories_request'),
    success: createAction('product/categories_success', resolve => (payload: CategoryModel[]) =>
      resolve(payload),
    ),
    fail: createAction('product/categories_failure', resolve => (error: any) =>
      resolve(error),
    ),
  };

  static updateDefaultImage = {
    request: createAction('product/default_image_request', resolve => (payload: { id: string}) =>
      resolve(payload),
    ),
    success: createAction('product/default_image_success'),
    fail: createAction('product/default_image_failure', resolve => (error: any) =>
      resolve(error),
    ),
  };

  static uploadImage = {
    request: createAction('product/upload_image_request', resolve => (payload: UploadProductImagePayload) =>
      resolve(payload),
    ),
    success: createAction('product/upload_image_success'),
    fail: createAction('product/upload_image_failure', resolve => (error: any) =>
      resolve(error),
    ),
  };

  static updateDetails = {
    request: createAction('product/update_details_request', resolve => (payload: UpdateProductDetailsPayload) =>
      resolve(payload),
    ),
    success: createAction('product/update_details_success'),
    fail: createAction('product/update_details_failure', resolve => (error: any) =>
      resolve(error),
    ),
    set: createAction('product/update_details_set', resolve => (payload: ProductModel) =>
      resolve(payload),
    ),
  };

  static deleteImage = {
    request: createAction('product/delete_image_request', resolve => (payload: DeleteProductImagePayload) =>
      resolve(payload),
    ),
    success: createAction('product/delete_image_success'),
    fail: createAction('product/delete_image_failure', resolve => (error: any) =>
      resolve(error),
    ),
  };

  static defaultImage = {
    request: createAction('product/default_image_request', resolve => (payload: DefaultProductImagePayload) =>
      resolve(payload),
    ),
    success: createAction('product/default_image_success'),
    fail: createAction('product/default_image_failure', resolve => (error: any) =>
      resolve(error),
    ),
  };

  static removeAssignedCategory = {
    request: createAction('product/remove_assigned_category_request', resolve => (payload: RemoveAssignedProductCategoryPayload) =>
      resolve(payload),
    ),
    success: createAction('product/remove_assigned_category_success'),
    fail: createAction('product/remove_assigned_category_failure', resolve => (error: any) =>
      resolve(error),
    ),
  };

  // Upload image modal
  static showUploadImageModal = createAction('product/show_upload_image_modal', resolve => (payload: boolean) => resolve(payload));

  static setUploadImageModalImage = createAction('product/upload_image_modal_image', resolve => (payload: string) => resolve(payload));

  // Update details
  static showUpdateDetailsModal = createAction('product/show_update_details_modal', resolve => (payload: boolean) => resolve(payload));

  // Update default image
  static showUpdateDefaultImageModal = createAction('product/show_update_default_image_modal', resolve => (payload: boolean) => resolve(payload));

  // Delete image modal
  static showDeleteImageModal = createAction('product/show_delete_image_modal', resolve => (payload: boolean) => resolve(payload));

  static setDeleteImageModalId = createAction('product/set_delete_image_modal_id', resolve => (payload: string) => resolve(payload));

  // Fetch available categories
  static categoriesToAssign = {
    request: createAction('product/categories_to_assign_request'),
    
    success: createAction('product/categories_to_assign_success', resolve => (payload: CategoryModel[]) =>
      resolve(payload),
    ),

    fail: createAction('product/categories_to_assign_failure', resolve => (error: any) =>
      resolve(error),
    ),
  };

  // Update Assign Category Modal State
  static assignCategoryToProductModal = createAction('product/assign_category_to_product_modal', resolve => (payload: AssignCategoryToProduct) => resolve(payload));

  // Assign category to product
  static assignCategory = {
    request: createAction('product/assign_category_to_product_request', resolve => (payload: AssignCategoryToProductPayload) =>
      resolve(payload),
    ),
    
    success: createAction('product/assign_category_to_product_success'),

    fail: createAction('product/assign_category_to_product_failure', resolve => (error: any) =>
      resolve(error),
    ),
  };

  // Update Assign Category Modal State
  static showAssignCategoryModal = createAction('product/show_assign_category_to_product_modal', resolve => (payload: boolean) => resolve(payload));
}
