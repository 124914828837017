import { ActionType, getType } from 'deox';
import { SagaIterator } from 'redux-saga';
import { put, takeLatest, call, select } from 'typed-redux-saga';

import './actions';
import axios from 'axios';
import { HomeApis } from '../../../server/api/Home';
import { ProductActions } from './actions';
import { ProductsApis } from '../../../server/api/Products';
import { AppActions } from '../../../modules/app/actions';
import { AppApis } from '../../../server/api/App';


function* product({ payload }: ActionType<typeof ProductActions.product.request>): SagaIterator {
  try {
    console.log('product saga');
    // Fetching Categories
    yield* put(AppActions.insureToken()) as any;
    const categoriesResponse = yield* call(AppApis.categories) as any;
    console.log('categories response: ');
    console.log(categoriesResponse.data);
    yield* put(ProductActions.categories.success(categoriesResponse.data.data)) as any;

    // Fetching Product
    const { data } = yield* call(ProductsApis.product, payload) as any;
    console.log('product response: ');
    console.log(data);
    yield* put(ProductActions.product.success({
      product: data
    })) as any;
  } catch (error) {
    console.log(error)
    yield* put(ProductActions.product.fail('')) as any;
  }
}

function* uploadImage({ payload }: ActionType<typeof ProductActions.uploadImage.request>): SagaIterator {
  try {
    console.log('upload product image saga');
    // Fetching Categories
    yield* put(AppActions.insureToken()) as any;

    // Fetching Product
    const { data } = yield* call(ProductsApis.uploadImage, payload) as any;
    console.log('upload product image response: ');
    console.log(data);
    yield* put(ProductActions.uploadImage.success()) as any;

    const product = yield* call(ProductsApis.product, {id: payload.product_id}) as any;
    console.log('product response: ');
    console.log(product.data);
    yield* put(ProductActions.product.success({
      product: product.data
    })) as any;
  } catch (error) {
    console.log(error)
    yield* put(ProductActions.uploadImage.fail('')) as any;
  }
}

function* deleteImage({ payload }: ActionType<typeof ProductActions.deleteImage.request>): SagaIterator {
  try {
    console.log('delete product image saga');
    console.log(payload);
    // Fetching Categories
    yield* put(AppActions.insureToken()) as any;

    // Fetching Product
    const { data } = yield* call(ProductsApis.deleteImage, payload) as any;
    console.log('delete product image response: ');
    console.log(data);
    yield* put(ProductActions.deleteImage.success()) as any;

    const product = yield* call(ProductsApis.product, {id: payload.product_id}) as any;
    console.log('product response: ');
    console.log(product.data);
    yield* put(ProductActions.product.success({
      product: product.data
    })) as any;
  } catch (error) {
    console.log(error)
    yield* put(ProductActions.deleteImage.fail('Something went wrong while trying to delete product image.')) as any;
  }
}

function* defaultImage({ payload }: ActionType<typeof ProductActions.defaultImage.request>): SagaIterator {
  try {
    console.log('delete product image saga');
    console.log(payload);
    // Fetching Categories
    yield* put(AppActions.insureToken()) as any;

    // Fetching Product
    const { data } = yield* call(ProductsApis.defaultImage, payload) as any;
    console.log('delete product image response: ');
    console.log(data);
    yield* put(ProductActions.defaultImage.success()) as any;

    const product = yield* call(ProductsApis.product, {id: payload.product_id}) as any;
    console.log('product response: ');
    console.log(product.data);
    yield* put(ProductActions.product.success({
      product: product.data
    })) as any;
  } catch (error) {
    console.log(error)
    yield* put(ProductActions.defaultImage.fail('Something went wrong while trying to delete product image.')) as any;
  }
}

function* categoriesToAssign({ }: ActionType<typeof ProductActions.categoriesToAssign.request>): SagaIterator {
  try {
    console.log('request categories saga triggered')
    yield* put(AppActions.insureToken()) as any;
    const { data } = yield* call(AppApis.categories) as any;
      console.log('categories response: ');
      console.log(data);
      yield* put(ProductActions.categoriesToAssign.success(data.data)) as any;
  } catch (error) {
    yield* put(ProductActions.categoriesToAssign.fail('Something went wrong while trying to fetch categories, Please try again later.')) as any;
  }
}

function* assignCategory({ payload }: ActionType<typeof ProductActions.assignCategory.request>): SagaIterator {
  try {
    console.log('request assign category saga triggered')
    yield* put(AppActions.insureToken()) as any;
    const { data } = yield* call(ProductsApis.assignCategory, payload) as any;
    
      console.log('assign category response: ');
      console.log(data);
      yield* put(ProductActions.assignCategory.success()) as any;


      const product = yield* call(ProductsApis.product, {id: payload.productId}) as any;
      yield* put(ProductActions.product.success({
        product: product.data
      })) as any;
  } catch (error) {
    console.log(error);
    if (error.response.status == 400) {
      console.log(error.response.data['messsage']);
      yield* put(ProductActions.assignCategory.fail(error.response.data['messsage'])) as any;
    } else {
      yield* put(ProductActions.assignCategory.fail("Something went wrong while trying to assign category to coupon, Please try again later.")) as any;
    }
  }
}

function* removeAssignedCategory({ payload }: ActionType<typeof ProductActions.removeAssignedCategory.request>): SagaIterator {
  try {
    console.log('request remove assigned category saga triggered')
    yield* put(AppActions.insureToken()) as any;
    const { data } = yield* call(ProductsApis.removeAssignCategory, payload) as any;
    console.log('remove assigned category response: ');
    console.log(data);
    yield* put(ProductActions.assignCategory.success()) as any;
    const product = yield* call(ProductsApis.product, {id: payload.productId}) as any;
    yield* put(ProductActions.product.success({
      product: product.data
    })) as any;
  } catch (error) {
    console.log(error);
    if (error.response.status == 400) {
      console.log(error.response.data['messsage']);
      yield* put(ProductActions.assignCategory.fail(error.response.data['messsage'])) as any;
    } else {
      yield* put(ProductActions.assignCategory.fail("Something went wrong while trying to assign category to coupon, Please try again later.")) as any;
    }
  }
}

function* updateDetails({ payload }: ActionType<typeof ProductActions.updateDetails.request>): SagaIterator {
  try {
    console.log('request remove assigned category saga triggered')
    yield* put(AppActions.insureToken()) as any;
    const { data } = yield* call(ProductsApis.updateDetails, payload) as any;
    console.log('remove assigned category response: ');
    console.log(data);
    yield* put(ProductActions.updateDetails.success()) as any;
    const product = yield* call(ProductsApis.product, {id: payload.id}) as any;
    yield* put(ProductActions.product.success({
      product: product.data
    })) as any;
  } catch (error) {
    console.log(error);
    if (error.response.status == 400) {
      console.log(error.response.data['messsage']);
      yield* put(ProductActions.updateDetails.fail(error.response.data['messsage'])) as any;
    } else {
      yield* put(ProductActions.updateDetails.fail("Something went wrong while trying to assign category to coupon, Please try again later.")) as any;
    }
  }
}


export function* watchProduct(): SagaIterator {
  yield* takeLatest(ProductActions.product.request, product) as any;
  yield* takeLatest(ProductActions.uploadImage.request, uploadImage) as any;
  yield* takeLatest(ProductActions.deleteImage.request, deleteImage) as any;
  yield* takeLatest(ProductActions.defaultImage.request, defaultImage) as any;
  yield* takeLatest(ProductActions.assignCategory.request, assignCategory) as any;
  yield* takeLatest(ProductActions.categoriesToAssign.request, categoriesToAssign) as any;
  yield* takeLatest(ProductActions.removeAssignedCategory.request, removeAssignedCategory) as any;
  yield* takeLatest(ProductActions.updateDetails.request, updateDetails) as any;
}
