import { createReducer } from 'deox';
import { produce } from 'immer';

import {
  AuthActions,
} from './actions';
import { AuthTypes } from './types';

const initialState: AuthTypes = {
  loading: false,
  error: undefined,
};

export const authReducer = createReducer(initialState, handleAction => [
  // Login
  handleAction(AuthActions.login.request, (state, payload ) =>
    produce(state, draft => {
      console.log('requesting login');
      draft.loading = true;
    }),
  ),
  handleAction(AuthActions.login.success, (state, { payload }: any) =>
    produce(state, draft => {
      draft.loading = false;
      draft.error = undefined;
    }),
  ),
  handleAction(AuthActions.login.fail, (state, { payload }: any) =>
    produce(state, draft => {
      console.log('login failed called')
      draft.loading = false;
      draft.error = payload;
    }),
  ),
]);