import { createAction } from 'deox';
import { SliderModel } from '../../../server/models/slider';
import { ProductModel } from '../../../server/models/product';
import { AttributeModel } from '../../../server/models/attribute';
import { ProductsPayload } from '../../../server/payload/products';
import { CategoryModel } from 'src/server/models/category';
import { CouponModel } from 'src/server/models/coupon';
import { CouponPayload } from 'src/server/payload/copoun';
import { CreateBrandModel } from 'src/server/models/create_brand';
import { CreateSliderModel } from 'src/server/models/create_slider';
import { DeleteSliderPayload } from 'src/server/payload/delete_slider';
import { DeleteSliderModal } from 'src/server/models/delete_slider_modal';


export class SliderActions {
  static sliders = {
    request: createAction('slider/sliders_request'),
    success: createAction('slider/sliders_success', resolve => (payload: SliderModel[]) =>
      resolve(payload),
    ),
    fail: createAction('slider/sliders_failure', resolve => (error: any) =>
      resolve(error),
    ),
  };

  static create = {
    request: createAction('slider/create_slider_request', resolve => (payload: CreateSliderModel) =>
      resolve(payload),
    ),
    success: createAction('slider/create_slider_success'),
    fail: createAction('slider/create_slider_failure', resolve => (error: any) =>
      resolve(error),
    ),
  };

  static delete = {
    request: createAction('slider/delete_slider_request', resolve => (payload: DeleteSliderPayload) =>
      resolve(payload),
    ),
    success: createAction('slider/delete_slider_success'),
    fail: createAction('slider/delete_slider_failure', resolve => (error: any) =>
      resolve(error),
    ),
  };


  static createSliderModel = createAction('slider/create_slider_model', resolve => (payload: boolean) =>
    resolve(payload),
  );

  static setCreateSliderModelLink = createAction('slider/create_slider_model_link', resolve => (payload: String) =>
    resolve(payload),
  );

  static setCreateSliderModelImage = createAction('slider/create_category_model_image', resolve => (payload: String) =>
    resolve(payload),
  );

  static deleteSliderModal = createAction('slider/delete_slider_modal', resolve => (payload: DeleteSliderModal) =>
    resolve(payload),
  );

  static reset = createAction('slider/reset');
}