//export const BASE_URL = 'https://najmatigroup.com/hiphone/'; // dev
// export const BASE_URL = 'http://38.242.157.171/~vpassae/hiphone/'; // dev
export const BASE_URL = 'https://hiphonetelecom.com/v2/';

export const UPLOADS_URL = `${BASE_URL}/uploads/`;

export const LOGIN_URL = 'Login/login_admin';
export const REGISTER_URL = 'Login/register';
export const CREATE_USER_URL = 'Login/admin_create_user';

export const SIMPLE_PRODUCT_URL = 'Api/product_simple';
export const CATEGORIES_URL = 'Api/Categories';
export const ATTRIBUTES_URL = 'Api/admin_attributes';
export const CREATE_ATTRIBUTE_URL = 'Api/admin_create_attribute';
export const CREATE_ATTRIBUTE_VALUE_URL = 'Api/admin_create_attribute_value';
export const BRANDS_URL = 'Api/admin_brands';
export const CREATE_CATEGORY_URL = 'Api/admin_create_category';
export const CREATE_BRAND_URL = 'Api/admin_create_brand';
export const PRODUCTS_URL = 'Api/products';
export const FLASH_SELLS_URL = 'Api/flash_sells';
export const BEST_SELLERS_URL = 'Api/best_sellers';
export const YOU_MAY_LIKE_URL = 'Api/you_may_like';
export const PRODUCT_URL = 'Api/admin_product';
export const ADD_PRODUCT_IMAGE_URL = 'Api/admin_add_product_image';
export const DELETE_PRODUCT_IMAGE_URL = 'Api/admin_delete_product_image';
export const DEFAULT_PRODUCT_IMAGE_URL = 'Api/admin_default_product_image';
export const ASSIGN_CATEGORY_TO_PRODUCT_URL = 'Api/admin_assign_category_to_product';
export const REMOVE_ASSIGNED_PRODUCT_CATEGORY_URL = 'Api/admin_remove_assigned_product_from_category';
export const UPDATE_PRODUCT_DETAILS_URL = 'Api/admin_update_product_details';
export const WISHLIST_URL = 'Api/favourit_products';
export const ADD_TO_WISHLIST_URL = 'Api/favourite'; //product_id=
export const REMOVE_FROM_WISHLIST_URL = 'Api/un_favourite'; // product_id=
export const CART_URL = 'Api/cart';
export const REMOVE_FROM_CART_URL = 'Api/removeFromCart'; // item_id=
export const ADD_TO_CART_URL = 'Api/addToCart'; // {item_id, quantity}
export const UPDATE_CART_URL = 'Api/updateCart'; // item_id=
export const ORDER_URL = 'Api/admin_order'; // id=
export const CANCEL_ORDER_URL = 'Api/cancele_order'; // order_id

export const ACCEPT_ORDER_URL = 'Api/admin_accept_order'; // order_id
export const REJECT_ORDER_URL = 'Api/admin_reject_order'; // order_id
export const COMPLETE_ORDER_URL = 'Api/admin_complete_order'; // order_id

export const CHECKOUT_URL = 'Api/order'; // [{quantity, price, item_id}]
export const ADDRESS_URL = 'Api/address';
export const ADDRESSES_URL = 'Api/addresses';
export const SLIDER_URL = 'Api/slider';
export const CREATE_SLIDER_URL = 'Api/admin_create_slider';
export const DELETE_SLIDER_URL = 'Api/admin_delete_slider';
export const REPAIR_URL = 'Api/repair';
export const USER_INFO_URL = 'Api/userInfo';
export const CALCULATE_URL = 'Api/calc';
// export const API_URL = 'https://busyhive-back-stage.appelloproject.xyz/api/'; // stage
export const ORDERS_URL = 'Api/admin_orders';
// export const API_URL = ''; // prod
export const PAYMENT_LINK_URL = 'Api/paymentLink'; // order_id

export const COUPONS_URL = 'Api/admin_coupons';
export const COUPON_URL = 'Api/admin_coupon';
export const CREATE_COUPON_URL = 'Api/admin_create_coupon';
export const ASSIGN_CATEGORY_TO_COUPON_URL = 'Api/admin_assign_category_to_coupon';
export const ASSIGN_PRODUCT_TO_COUPON_URL = 'Api/admin_assign_product_to_coupon';

export const USERS_URL = 'Api/admin_users';

// NGenius
export const NGENIUS_API_KEY = 'N2FkNzdiMDItNTdhNy00OWIwLTg5MjctYmQ3MTM4MTY1ZjRmOmE0MTg1MDA4LWEyZDYtNDkwOC1iN2VhLWRlZmEyOGIwNWI1OQ==';
export const NGENIUS_OUTLET_REFERENCE = '893ce64a-8526-4cf9-b2d4-aecc744f9e67';

export const NGENIUS_LIVE_API_KEY = 'ZmZiZDM3NmMtYmE0OS00MzM5LTgyYmItZTczZDQ3ZThlNThjOjY3MzA4MDQ1LTk4MzAtNDExZi1iMTVjLWUzNTI3ZDhmYmZlNw==';
export const NGENIUS_LIVE_OUTLET_REFERENCE = '18e6d83b-dca4-42e2-af6e-c56c35840590';

export const NGENIUS_CURRENCY = 'AED';
export const NGENIUS_COUNTRY_CODE = 'UAE';
export const NGENIUS_CREATE_TOKEN_URL = 'https://api-gateway.sandbox.ngenius-payments.com/identity/auth/access-token';
export const NGENIUS_CREATE_ORDER_URL = 'https://api-gateway.sandbox.ngenius-payments.com/transactions/outlets/$outletReference/orders';

// Tabby
// Doc: https://docs.tabby.ai/introduction/technical-requirements
// Public Key: a key which is associated with your Merchant and is used to authorize Tabby's Checkout API.
// Secret Key: a key which is associated with your Merchant and is used to authorize Tabby's Payment and optionally Checkout APIs.
export const TABBY_PUBLIC_TEST_API_KEY = "pk_test_12c04f33-2c1d-4e5d-afe1-0c2077afa273"
export const TABBY_SECRET_TEST_API_KEY = "sk_test_3412a909-6edd-4ab7-82f2-d4037ee78cc8"
export const TABBY_MERCHANT_CODE = "hiphone"

export const validationRegex = {
  telephoneNumber: /^([0-9]{9,15})$/,
  username: /^([A-Za-z0-9.@_]{4,32})$/,
  password: /^(.{8,32})$/,
  positiveNumber: /^\-?\d+((\.|\,)\d+)?$/,
};
