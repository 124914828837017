import { Helmet } from 'react-helmet-async';
import PageHeader from '../components/ProductsHeader';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import { Grid, Container } from '@mui/material';
import Footer from 'src/components/Footer';

import Orders from '../components/Products';
import { useEffect, useState } from 'react';
import { RootState } from 'src/store/rootState';
import { useSelector } from 'react-redux';
import { useAction } from 'src/utils/hooks/action';
import { OrdersActions } from 'src/pages/orders/module/actions';
import { ProductsActions } from '../module/actions';
import ProductsHeader from '../components/ProductsHeader';
import Products from '../components/Products';

function BestSellerProductsPage() {
  const { loading, error } = useSelector((state: RootState) => state.orders);
  const productsAction = useAction(ProductsActions.products.request);

  useEffect(() => {
    productsAction({ page: 0, min_price: 0, is_best_seller: 1});
  }, [])

  if(loading) {
    <>Loading...</>
  }
  
  return (
    <>
      <Helmet>
        <title>Products - Best Seller</title>
      </Helmet>
      <PageTitleWrapper>
        <ProductsHeader title={'Unavailable Products'} description="" />
      </PageTitleWrapper>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <Products />
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </>
  );
}

export default BestSellerProductsPage;
