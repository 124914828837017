import { createReducer } from 'deox';
import { produce } from 'immer';

import {
  OrderActions,
} from './actions';
import { OrderTypes } from './types';

const initialState: OrderTypes = {
  loadingOrder: false,
  products: [],
  billingAddress: {
    first_name: '',
    last_name: '',
    company: '',
    country: '',
    street: '',
    address: '',
    title: '',
    apartment: '',
    city: '',
    state: '',
    phone: '',
    email: '',
    note: undefined
  },
  shippingAddress: {
    first_name: '',
    last_name: '',
    company: '',
    country: '',
    street: '',
    address: '',
    title: '',
    apartment: '',
    city: '',
    state: '',
    phone: '',
    email: '',
    note: undefined
  },
  order: {
    id: '',
    date: '',
    total_quantity: '0',
    subtotal: '',
    discount: '',
    delivery_fee: '0',
    item_total: '',
    total: '',
    payment_method: '',
    status: 'Waiting For Payment',
    order_from: '',
    customer_id: '',
    cancelled_by_customer: '',
    note: '',
    reference_id: '',
    payment_link: '',
    coupon: '',
    payment_eligible: 0
  },
  acceptOrderModal: false,
  rejectOrderModal: false,
  completeOrderModal: false,

  loadingAcceptOrder: false,
  loadingRejectOrder: false,
  loadingCompleteOrder: false,

  acceptOrderErrorMessage: '',
  rejectOrderErrorMessage: '',
  completeOrderErrorMessage: '',
  orderAccepted: false,
  orderRejected: false,
  orderCompleted: false
};

export const orderReducer = createReducer(initialState, handleAction => [
  // Order
  handleAction(OrderActions.order.request, (state, { payload } ) =>
    produce(state, draft => {
      draft.order = initialState.order;
      draft.billingAddress = initialState.billingAddress;
      draft.shippingAddress = initialState.shippingAddress;
      draft.products = [];
      draft.loadingOrder = true;
    }),
  ),
  
  handleAction(OrderActions.order.success, (state, { payload } ) =>
    produce(state, draft => {
      draft.loadingOrder = false;
      draft.order = payload.order;
      draft.products = payload.products;
      draft.billingAddress = payload.billingAddress;
      draft.shippingAddress = payload.shippingAddress;
    }),
  ),

  handleAction(OrderActions.order.fail, (state ) =>
    produce(state, draft => {
      draft.loadingOrder = false;
    }),
  ),

  // Accept Order
  handleAction(OrderActions.accept.request, (state, { payload } ) =>
    produce(state, draft => {
      draft.loadingAcceptOrder = true;
      draft.acceptOrderErrorMessage = '';
    }),
  ),

  handleAction(OrderActions.accept.success, (state) =>
    produce(state, draft => {
      draft.loadingAcceptOrder = false;
      draft.acceptOrderModal = false
      // draft.orderAccepted = true
      draft.order = {...draft.order, status: 'Processing'}
    }),
  ),

  handleAction(OrderActions.accept.fail, (state, { payload }) =>
    produce(state, draft => {
      draft.acceptOrderErrorMessage = payload
      draft.loadingAcceptOrder = false;
    }),
  ),

  // Reject Order
  handleAction(OrderActions.reject.request, (state, { payload } ) =>
    produce(state, draft => {
      draft.loadingRejectOrder = true;
      draft.rejectOrderErrorMessage = '';
    }),
  ),

  handleAction(OrderActions.reject.success, (state) =>
    produce(state, draft => {
      draft.loadingRejectOrder = false;
      draft.rejectOrderModal = false
      // draft.orderRejected = true
      draft.order = {...draft.order, status: 'Rejected'}
    }),
  ),

  handleAction(OrderActions.reject.fail, (state, { payload } ) =>
    produce(state, draft => {
      draft.rejectOrderErrorMessage = payload
      draft.loadingRejectOrder = false;
    }),
  ),

  // Complete Order
  handleAction(OrderActions.complete.request, (state, { payload } ) =>
    produce(state, draft => {
      draft.loadingCompleteOrder = true;
      draft.completeOrderErrorMessage = '';
    }),
  ),

  handleAction(OrderActions.complete.success, (state) =>
    produce(state, draft => {
      draft.loadingCompleteOrder = false;
      draft.completeOrderModal = false
      // draft.orderCompleted = true
      draft.order = {...draft.order, status: 'Completed'}
    }),
  ),
  
  handleAction(OrderActions.complete.fail, (state, { payload } ) =>
    produce(state, draft => {
      draft.completeOrderErrorMessage = payload
      draft.loadingCompleteOrder = false;
    }),
  ),

  // Show/Hide accept order modal
  handleAction(OrderActions.acceptOrderModal, (state, { payload } ) =>
    produce(state, draft => {
      console.log('accept order modal payload')
      console.log(payload)
      draft.acceptOrderModal = payload;
    }),
  ),

  // Show/Hide reject order modal
  handleAction(OrderActions.rejectOrderModal, (state, { payload } ) =>
    produce(state, draft => {
      draft.rejectOrderModal = payload;
    }),
  ),

  // Show/Hide complete order modal
  handleAction(OrderActions.completeOrderModal, (state, { payload } ) =>
    produce(state, draft => {
      draft.completeOrderModal = payload;
    }),
  ),

  // Reset
  handleAction(OrderActions.reset, (state) =>
    produce(state, draft => {
      draft.loadingAcceptOrder = false;
      draft.loadingRejectOrder = false;
      draft.loadingCompleteOrder = false;
      draft.acceptOrderModal = false;
      draft.rejectOrderModal = false;
      draft.completeOrderModal = false;
      draft.rejectOrderErrorMessage = undefined
      draft.acceptOrderErrorMessage = undefined
      draft.completeOrderErrorMessage = undefined
      draft.orderAccepted = false
      draft.orderCompleted = false
      draft.orderRejected = false
    }),
  ),
  
  // Order Accepted
  handleAction(OrderActions.setAccepted, (state, { payload } ) =>
    produce(state, draft => {
      draft.orderAccepted = payload;
    }),
  ),
  handleAction(OrderActions.setRejected, (state, { payload } ) =>
    produce(state, draft => {
      draft.orderRejected = payload;
    }),
  ),
  handleAction(OrderActions.setCompleted, (state, { payload } ) =>
    produce(state, draft => {
      draft.orderCompleted = payload;
    }),
  ),
]);
