import { Typography, Button, Grid } from '@mui/material';
import { Api } from 'src/server/api';


function OrderHeader(props: { title: string, description: string }) {

  return (
    <Grid container direction="row" justifyContent="space-between" alignItems="center">
      <Grid item>
        <Typography variant="h3" component="h3" gutterBottom>
          {props.title}
        </Typography>
        <Typography variant="subtitle2">
          {props.description}
        </Typography>
      </Grid>

      <>
      </>
      {<div style={{display:'flex'}}>

        { 
        <div>
          <Button
            variant="contained"
            onClick={() => {
              let token = Api.getAxios().defaults.headers.Token
              window.open(`https://hiphonetelecom.com/v2/Api/device_request?key=${token}`, '_blank');
            }}
          >
            Export
          </Button>
        </div> 
        }
      </div>}
    </Grid>
  );
}

export default OrderHeader;
