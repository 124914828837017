import { ActionType, getType } from 'deox';
import { SagaIterator } from 'redux-saga';
import { put, takeLatest, call, select } from 'typed-redux-saga';

import './actions';
import { ProductsApis } from '../../../server/api/Products';
import { AppActions } from '../../../modules/app/actions';
import { AppApis } from '../../../server/api/App';
import { RootState } from '../../../store/rootState';
import { CouponsApis } from 'src/server/api/Coupons';
import { AttributesActions } from './actions';
import { AttributesApis } from 'src/server/api/Attributes';


function* attributes({ }: ActionType<typeof AttributesActions.attributes.request>): SagaIterator {
  try {
    console.log('request attributes saga triggered')
    yield* put(AppActions.insureToken()) as any;
    const { data } = yield* call(AttributesApis.attributes) as any;
      console.log('attributes response: ');
      console.log(data);
      yield* put(AttributesActions.attributes.success(data.data)) as any;
  } catch (error) {
    console.log(error);
    yield* put(AttributesActions.attributes.fail('Something went wrong while trying to fetch categories, Please try again later.')) as any;
  }
}

// function* create({ payload }: ActionType<typeof AttributesActions.create.request>): SagaIterator {
//   try {
//     console.log('request create category saga triggered')
//     yield* put(AppActions.insureToken()) as any;
//     const { data } = yield* call(AppApis.createCategories, payload) as any;
//       console.log('create category response: ');
//       console.log(data);
//       yield* put(AttributesActions.create.success()) as any;

//       const categoriesData = yield* call(AppApis.categories) as any;
//       yield* put(AttributesActions.categories.success(categoriesData.data.data)) as any;
//   } catch (error) {
//     console.log(error.response)
//     yield* put(CategoriesActions.create.fail('Something went wrong while trying to fetch categories, Please try again later.')) as any;
//   }
// }

export function* watchAttributes(): SagaIterator {
  yield* takeLatest(AttributesActions.attributes.request, attributes) as any;
  // yield* takeLatest(CategoriesActions.create.request, create) as any;
}