import { Api } from './Api';
import {  getUrl } from '../../utils/helper';
import { BRANDS_URL, CREATE_BRAND_URL } from '../../configs';

export class BrandsApis {
  static brands(): ReturnType<typeof Api.get> {
    return Api.get(BRANDS_URL);
  }
  
  static createBrand(payload): ReturnType<typeof Api.post> {
    return Api.post(CREATE_BRAND_URL, payload);
  }
}
