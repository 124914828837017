import {
  BrowserRouter,
  // HashRouter,
  Navigate,
  Route,
  Routes,
  useNavigate,
  useRoutes
} from 'react-router-dom';
// import router, { Loader } from 'src/router';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import { Suspense, lazy, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from './store/rootState';
import { AppActions } from './modules/app/actions';
import { useAction } from './utils/hooks/action';
import LoginPage from './pages/auth';
import ProductPage from './pages/product';
import ProductsPage from './pages/products';
import FlashSellProductsPage from './pages/products/flash_sell';
import BestSellerProductsPage from './pages/products/best_seller';
import YouMayAlsoLikeProductsPage from './pages/products/you_may_also_like';
import SuspenseLoader from './components/SuspenseLoader';
import BaseLayout from './layouts/BaseLayout';
import SidebarLayout from './layouts/SidebarLayout';
import DeviceRequests from 'src/pages/device_requests';

export const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

export const ProtectedRoute = ({ children }) => {
  const { login } = useSelector((state: RootState) => state.app);
  if (!login) {
    return <Navigate to="/" replace />;
  }

  return <SidebarLayout>{children}</SidebarLayout>;
};

function App() {
  // const content = useRoutes(router);
  const navigate = useNavigate();
  const { nav } = useSelector((state: RootState) => state.app);
  const removeNavigate = useAction(AppActions.removeNavigate);

  useEffect(() => {
    if(nav) {
      removeNavigate();
      navigate(nav, { replace: true });
    }
  }, [nav])

  // Orders
  const PendingOrders = Loader(lazy(() => import('src/pages/orders/pending')));

  const WaitingForPaymentOrders = Loader(
    lazy(() => import('src/pages/orders/waiting_for_payment'))
  );

  const ProcessingOrders = Loader(
    lazy(() => import('src/pages/orders/processing'))
  );

  const CompletedOrders = Loader(
    lazy(() => import('src/pages/orders/completed'))
  );

  const RejectedOrders = Loader(
    lazy(() => import('src/pages/orders/rejected'))
  );

  const RefundedOrders = Loader(
    lazy(() => import('src/pages/orders/refunded'))
  );

  const FailedOrders = Loader(lazy(() => import('src/pages/orders/failed')));

  const CancelledOrders = Loader(
    lazy(() => import('src/pages/orders/cancelled'))
  );

  const Order = Loader(lazy(() => import('src/pages/order')));

  const Products = Loader(lazy(() => import('src/pages/products')));

  const Product = Loader(lazy(() => import('src/pages/product')));

  const UnAvailableProducts = Loader(
    lazy(() => import('src/pages/products/unavailable'))
  );
  const BestSellerProducts = Loader(
    lazy(() => import('src/pages/products/best_seller'))
  );
  const YouMayAlsoLikeProducts = Loader(
    lazy(() => import('src/pages/products/you_may_also_like'))
  );

  const Coupons = Loader(lazy(() => import('src/pages/coupons')));

  const Coupon = Loader(lazy(() => import('src/pages/coupon')));

  const Sliders = Loader(lazy(() => import('src/pages/sliders')));

  const Users = Loader(lazy(() => import('src/pages/users')));

  const CreateUser = Loader(lazy(() => import('src/pages/users/create')));

  const Categories = Loader(lazy(() => import('src/pages/categories')));

  const Brands = Loader(lazy(() => import('src/pages/brands')));

  const Attributes = Loader(lazy(() => import('src/pages/attributes')));

  const DeviceRequest = Loader(lazy(() => import('src/pages/device_requests')));

  return (
    <Routes>
    <Route path="/" element={<LoginPage />} />
    
      <Route path="/sliders" element={<Sliders />} />
      <Route
        path="/orders"
        element={<Navigate to="waiting" replace />}
      ></Route>
      <Route
        path="/orders/:id"
        element={
          <ProtectedRoute>
            <Order />
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/orders/waiting"
        element={
          <ProtectedRoute>
            <WaitingForPaymentOrders />
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/orders/pending"
        element={
          <ProtectedRoute>
            <PendingOrders />
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/orders/processing"
        element={
          <ProtectedRoute>
            <ProcessingOrders />
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/orders/completed"
        element={
          <ProtectedRoute>
            <CompletedOrders />
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/orders/failed"
        element={
          <ProtectedRoute>
            <FailedOrders />
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/orders/refunded"
        element={
          <ProtectedRoute>
            <RefundedOrders />
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/orders/rejected"
        element={
          <ProtectedRoute>
            <RejectedOrders />
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/orders/cancelled"
        element={
          <ProtectedRoute>
            <CancelledOrders />
          </ProtectedRoute>
        }
      ></Route>

      <Route
        path="/products"
        element={
          <ProtectedRoute>
            <Products />
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/product/:id"
        element={
          <ProtectedRoute>
            <Product />
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/products/unavailable"
        element={
          <ProtectedRoute>
            <UnAvailableProducts />
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/products/best_sell"
        element={
          <ProtectedRoute>
            <BestSellerProductsPage />
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/products/you_may_also_like"
        element={
          <ProtectedRoute>
            <YouMayAlsoLikeProductsPage />
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/products/flash_sell"
        element={
          <ProtectedRoute>
            <FlashSellProductsPage />
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/products/flash_sell"
        element={
          <ProtectedRoute>
            <FlashSellProductsPage />
          </ProtectedRoute>
        }
      ></Route>

      <Route
        path="/coupons"
        element={
          <ProtectedRoute>
            <Coupons />
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/coupons/:id"
        element={
          <ProtectedRoute>
            <Coupon />
          </ProtectedRoute>
        }
      ></Route>

      <Route
        path="/users"
        element={
          <ProtectedRoute>
            <Users />
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/categories"
        element={
          <ProtectedRoute>
            <Categories />
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/brands"
        element={
          <ProtectedRoute>
            <Brands />
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/attributes"
        element={
          <ProtectedRoute>
            <Attributes />
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/create_user"
        element={
          <ProtectedRoute>
            <CreateUser />
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/device_requests"
        element={
          <ProtectedRoute>
            <DeviceRequests />
          </ProtectedRoute>
        }
      ></Route>
  </Routes>
  );
}
export default App;
